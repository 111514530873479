import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PortfolioTemplate = ({
  // data: portfolio,
  data: { portfolio },
  // data: { id, title, uri, content, featuredImage },
}: any) => {

  // const portfolio: any = portfolio
  const portfolioImage = {
    data:
      portfolio.featuredImage?.node?.localFile?.childImageSharp
        ?.gatsbyImageData,
    alt: portfolio.featuredImage?.node?.alt || ``,
  }
  // console.log("portfolio id:", id)

  return (
    <Layout>
      <Seo title={portfolio.title} description={portfolio.title} />
      <section className="max-w-screen-lg m-auto  mt-20 mb-4 p-1 md:p-0 px-5">
        <h1 className="text-center p-3  text-2xl font-bold text-white">
          {parse(portfolio.title)}
        </h1>

        <article
          className="portfolio-item max-w-screen-lg m-auto"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div
            className="max-w-2xl mx-auto overflow-hidden bg-white 
        rounded-lg shadow-md dark:bg-gray-800"
          >
            <div className="object-cover w-full">
              {portfolioImage?.data && (
                <GatsbyImage
                  image={portfolioImage.data}
                  alt={portfolioImage.alt}
                  className="object-cover w-full mb-5"
                />
              )}
            </div>
            <div className="p-6">
              <div>
                <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  {!!portfolio.content && (
                    <section itemProp="articleBody">
                      {parse(portfolio.content)}
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </article>
        <div className="w-full flex justify-items-center align-middle mt-4">
          <Link className=" btn text-white m-auto" to="/portfolio">
            <button
              className="mb-5 inline-flex items-center justify-center px-5 py-3 text-base font-medium
          text-white bg-gradient-to-r from-gray-700 to-gray-900
          hover:from-gray-600 hover:to-gray-600 w-auto
          transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
          rounded-lg shadow "
            >
              <span className="mx-2s">Return to Portfolio page</span>
            </button>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default PortfolioTemplate

export const portfolioItemPageQuery = graphql`
  query PorfolioItemById($id: String!) {
    portfolio: wpPortfolio(id: { eq: $id }) {
      id
      title
      uri
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
